<template>
  <Member
    title="Prof. Roberto Chiesa"
    description="Direttore Unità Operativa Chirurgia Vascolare"
    text="Il professor Roberto Chiesa è primario dell’Unità di Chirurgia Vascolare all’IRCCS Ospedale San Raffaele di Milano e Ordinario di Chirurgia Vascolare e direttore della Scuola di Specializzazione in Chirurgia Vascolare, presso la Facoltà di Medicina e Chirurgia dell’Università Vita-Salute San Raffaele.

Si laurea in Medicina e Chirurgia all’Università degli Studi di Milano, dove si specializza in Chirurgia Generale e in Chirurgia Vascolare. Successivamente si trasferisce all’Università degli Studi di Siena per specializzarsi in Cardiochirurgia.

Durante la sua formazione matura diverse esperienze all’estero: frequenta l’Università Claude Bernard di Lione, dove svolge attività di perfezionamento e ricerca nella chirurgia vascolare addominale e periferica e nel trapianto di rene e pancreas; la Thoracic and Cardiac Surgical Unit dell’Harefield Hospital di Harefield-Middlesex dove approfondisce le sue esperienze in chirurgia dell’aorta toraco-addominale e in chirurgia cardiaca (trapianto di cuore); infine il Baylor College of Medicine presso The Methodist Hospital di Houston, dove completa la sua esperienza nella chirurgia degli aneurismi dell’aorta toracica e toraco-addominale.

L’attività clinica e di ricerca del professor Roberto Chiesa si concentra sulla chirurgia nel distretto aortico-toraco-addominale; sul trattamento endovascolare e ibrido delle malattie a carico dell’arco aortico, dell’aorta toracica discendente e dell’aorta toraco-addominale per anomalie congenite e acquisite; sul trattamento delle lesioni carotidee extracraniche, e sul trattamento mini-invasivo endovascolare delle stenosi carotidee mediante stent e sistemi di protezione cerebrale anti-embolici. Sono più di 25.000 gli interventi chirurgici eseguiti in qualità di Primo operatore.

Il professor Chiesa è autore di 12 monografie e oltre 450 pubblicazioni scientifiche apparse su riviste accreditate come Journal of Vascular Surgery, European Journal of Vascular and Endovascular Surgery, Annals of Vascular Surgery, Journal of Endovascular Therapy e Journal of the American College of Cardiology. E’ stato membro del Comitato per la definizione delle Linee Guida della Società Europea di Chirurgia Vascolare (ESVS) per la patologia dell’aorta toracica pubblicate nel 2017, e delle Linee Guida congiunte della Società Europea di Chirurgia Vascolare (ESVS) e della Società Europea di Chirurgia Cardio-Toracica (EACTS) per la patologia dell’arco aortico pubblicate nel 2019.

Partecipa inoltre a numerosi trial internazionali per lo sviluppo di endoprotesi aortiche addominali e toraciche per il trattamento di aneurismi e dissezioni. Ha partecipato in qualità di relatore a più di 500 congressi nazionali e internazionali.

Il professor Chiesa è membro delle più prestigiose Società Nazionali ed Internazionali di Chirurgia Vascolare e presiede e organizza da 15 anni il congresso internazionale Aortic Surgery – How to do it che riunisce i massimi esperti nei campi della chirurgia vascolare, della cardiochirurgia e della cardioanestesia, con più di 1000 partecipanti provenienti da tutto il mondo.

Il professor Roberto Chiesa è primario dell’Unità di Chirurgia Vascolare all’IRCCS Ospedale San Raffaele di Milano e Ordinario di Chirurgia Vascolare e direttore della Scuola di Specializzazione in Chirurgia Vascolare, presso la Facoltà di Medicina e Chirurgia dell’Università Vita-Salute San Raffaele.

Si laurea in Medicina e Chirurgia all’Università degli Studi di Milano, dove si specializza in Chirurgia Generale e in Chirurgia Vascolare. Successivamente si trasferisce all’Università degli Studi di Siena per specializzarsi in Cardiochirurgia.

Durante la sua formazione matura diverse esperienze all’estero: frequenta l’Università Claude Bernard di Lione, dove svolge attività di perfezionamento e ricerca nella chirurgia vascolare addominale e periferica e nel trapianto di rene e pancreas; la Thoracic and Cardiac Surgical Unit dell’Harefield Hospital di Harefield-Middlesex dove approfondisce le sue esperienze in chirurgia dell’aorta toraco-addominale e in chirurgia cardiaca (trapianto di cuore); infine il Baylor College of Medicine presso The Methodist Hospital di Houston, dove completa la sua esperienza nella chirurgia degli aneurismi dell’aorta toracica e toraco-addominale.

L’attività clinica e di ricerca del professor Roberto Chiesa si concentra sulla chirurgia nel distretto aortico-toraco-addominale; sul trattamento endovascolare e ibrido delle malattie a carico dell’arco aortico, dell’aorta toracica discendente e dell’aorta toraco-addominale per anomalie congenite e acquisite; sul trattamento delle lesioni carotidee extracraniche, e sul trattamento mini-invasivo endovascolare delle stenosi carotidee mediante stent e sistemi di protezione cerebrale anti-embolici. Sono più di 25.000 gli interventi chirurgici eseguiti in qualità di Primo operatore."
    :formation="[
      '1974 Laurea in Medicina e Chirurgia, presso Università degli studi di Milano',
      '',
      '',
      '',
    ]"
    publication="Il professor Chiesa è autore di 12 monografie e oltre 450 pubblicazioni scientifiche apparse su riviste accreditate come Journal of Vascular Surgery, European Journal of Vascular and Endovascular Surgery, Annals of Vascular Surgery, Journal of Endovascular Therapy e Journal of the American College of Cardiology. E’ stato membro del Comitato per la definizione delle Linee Guida della Società Europea di Chirurgia Vascolare (ESVS) per la patologia dell’aorta toracica pubblicate nel 2017, e delle Linee Guida congiunte della Società Europea di Chirurgia Vascolare (ESVS) e della Società Europea di Chirurgia Cardio-Toracica (EACTS) per la patologia dell’arco aortico pubblicate nel 2019."
    :pub="true"
     :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Chiesa",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
